import imageCompression from 'browser-image-compression'
import { enqueueSnackbar } from 'notistack'

export function retry(fn, retriesLeft = 5, interval = 1000) {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error)
            return
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}

export const numDifferentiation = (val) => {
  if (val === undefined || val === null) return null
  if (val >= 10000000) val = parseFloat((val / 10000000).toFixed(2)) + ' Cr'
  else if (val >= 100000) val = parseFloat((val / 100000).toFixed(2)) + ' Lac'
  else if (val >= 1000) val = parseFloat((val / 1000).toFixed(2)) + ' K'
  return val < 10 ? '0' + val.toString() : val.toString()
}

export const isEmptyObject = (obj) => {
  return Object.keys(obj).length === 0
}

export const shortenLink = (link, maxLength = 25) => {
  if (link.length <= maxLength) {
    return link
  }

  const frontPart = link.substring(0, maxLength - 10)
  const endPart = link.substring(link.length - 5)
  return `${frontPart}...${endPart}`
}

export const compressImage = async (file, compressionSize) => {
  const options = {
    maxSizeMB: compressionSize,
    useWebWorker: true,
    fileType: 'image/webp',
    maxWidthOrHeight: 1080,
  }
  try {
    const compressedFile = await imageCompression(file, options)

    const finalImage = new File(
      [compressedFile],
      `${file.name.substring(0, file.name.lastIndexOf('.'))}.webp`,
      {
        type: compressedFile.type,
      },
    )

    return finalImage
  } catch (error) {
    console.error('Compression error:', error)
    enqueueSnackbar('Image compression failed', { variant: 'error' })
    return null
  }
}
