import { alpha } from '@mui/material/styles'

// ----------------------------------------------------------------------

// SETUP COLORS
const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
}

const PRIMARY = {
  lighter: '#D4E7FD',
  light: '#CEE3FD',
  main: '#C2DDFD',
  dark: '#AEC6E3',
  darker: '#9BB0CA',
  contrastText: '#000000',
}

const SECONDARY = {
  lighter: '#666666',
  light: '#4C4C4C',
  main: '#000',
  dark: '#323232',
  darker: '#191919',
  contrastText: '#FFFFFF',
}

const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
  contrastText: '#fff',
}

const SUCCESS = {
  lighter: '#A1F09F',
  light: '#43E040',
  main: '#04D600',
  dark: '#03A100',
  darker: '#025000',
  contrastText: GREY[800],
}

const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
  contrastText: GREY[800],
}

const ERROR = {
  lighter: '#FF8F8F',
  light: '#FF5656',
  main: '#FF1E1E',
  dark: '#BF1616',
  darker: '#800F0F',
  contrastText: '#FFFFFF',
}

const palette = {
  mode: 'light',
  common: {
    black: '#000000',
    white: '#FFFFFF',
    bg: 'linear-gradient(180deg, #C2DDFD 0%, #FFFFFF 36.5%)',
  },
  primary: PRIMARY,
  secondary: SECONDARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  grey: GREY,
  divider: alpha(GREY[500], 0.24),
  text: {
    primary: GREY[800],
    secondary: GREY[600],
    disabled: GREY[500],
  },
  background: {
    paper: '#fff',
    default: '#FFF',
    neutral: GREY[200],
  },
  action: {
    active: GREY[600],
    hover: alpha(GREY[500], 0.08),
    selected: alpha(GREY[500], 0.16),
    disabled: alpha(GREY[500], 0.8),
    disabledBackground: alpha(GREY[500], 0.24),
    focus: alpha(GREY[500], 0.24),
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
}

export default palette
